import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, CardBody, Card, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import CountDisplay from './components/CountDisplay';
import AddQuantitesModal from '../Form/AddQuantitesModal'
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apiCalls';
import Circle from 'mdi-react/CircleIcon'
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import Loader from '../App/Loader';

const Dashboard = (props) => {
  // State variables
  const [dashboardData, setDashboardData] = useState({});
  const [inventoryData, setInventoryData] = useState([]);
  const [showAddQuantitiesModal, setShowAddQuantitiesModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(undefined);
  const [loginRole, setLoginRole] = useState('');
  const [companyName, setCompanyName] = useState([])
  const [facilities, setFacilites] = useState([])
  const [storageUnits, setSorageUnits] = useState([])
  const [companiesData, setCompaniesData] = useState([])
  const [redirect, setRedirect] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  // Fetch inventory data on component mount
  useEffect(() => {
    let loginRole = localStorage.getItem("loginCredentials");
    loginRole = JSON.parse(loginRole);
    setLoginRole(loginRole.role);
    getCompanies()
    if (loginRole.role !== 'Super Admin') {
      setIsLoading(true)
      getInventoryList();
    }
  }, []);


  // Function to fetch inventory data
  const getInventoryList = async () => {
    const response = await fetchMethodRequest('GET', apiCalls.DashboardInventoryFlags);
    if (response) {
      setInventoryData(response);
      setIsLoading(false)

    }
  };



  const getCompanies = async () => {
    const getCompanies = await fetchMethodRequest('GET', apiCalls.dashboarddata)
      .then(async (response) => {
        setCompaniesData(response)
        // setCompanyName(response.companyUsers)
      })
  }

  // Function to handle opening of add quantities modal
  const handleOpenAddQuantitiesModal = (item) => {
    setSelectedRowData(item);
    setShowAddQuantitiesModal(true);
  };

  // Function to handle redirection
  const redirectToFacility = (companyName, facilityId, companyId) => {
    const url = `/facilities?companyName=${companyName}&facilityId=${facilityId}&comapnyId=${companyId}`;
    navigate(url);
  };

  const redirectToStorageUnit = (companyName, storageUnitId, companyId) => {
    const url = `/storageUnits?companyName=${companyName}&storageUnitId=${storageUnitId}&comapnyId=${companyId}`;
    navigate(url);
  };



  // Function to handle closing of add quantities modal
  const handleCloseAddQuantitiesModal = () => {
    setShowAddQuantitiesModal(false);
  };

  // Handle click event for dashboard icons
  const handleClick = (facilityName, cart, type) => {
    const item = inventoryData.find(item => item.facilityName === facilityName && item.storageUnitName === cart);
    if (facilityName && type && cart) {
      navigate(`/${type}/${facilityName}-${cart}`);
    }
  };


  return (
    <Container fluid className="dashboard">
      {/* Header */}
      <Row>
        <Col md={12}>
          <h3 className="dashboard-title">Home</h3>
        </Col>
      </Row>
      {/* Display CountDisplay */}
      <Row>
        <Col>
          {
            // loginRole !== 'Super Admin' &&
            <CountDisplay loginRole={loginRole}
              dashboardData={dashboardData}
            />}
        </Col>
      </Row>
      {/* Inventory Table */}
      {loginRole !== 'Super Admin' ?

        <Row>
          <Col>
            <Card className="dashboard-card-with-scroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="table-text"><b>Inventory Status</b></h5>
                </div>
                <Loader loader={isLoading} />
                <Table className="table--bordered table--head-accent" hover responsive style={{ overflowX: 'auto' }} >
                  <thead className="inventory-check-thead" style={{ fontSize: '13px' }}>
                    <tr className="table-thead table-text">
                      <th className="text-left">Facility</th>
                      <th className="text-left">Storage Unit</th>
                      <th className="text-center">Expiration</th>
                      <th className="text-center">Missing Items</th>
                      <th className="text-center">Recalls</th>
                      <th className="text-center">Weekly/Monthly Checks</th>
                      <th className="text-center">Battery Check</th>
                      <th className="text-center">Track</th>
                      <th className="text-center">Add</th>
                    </tr>
                  </thead>
                  {/* Inventory Table Body */}
                  {/* <div style={{ overflowX: 'auto' }}> */}
                  <tbody className="inventory-check-tbody">
                    {inventoryData.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left text-capitalize" style={{ paddingTop: '30px' }}>{item.facilityName}</td>
                        <td>
                          <div className="wrap-text">
                            <Link className="text-left text-capitalize text-decoration dashboard_textalign" to={`/inventoryCheckLists?storageUnitId=${encodeURIComponent(item.storageUnitId)}&storageUnitName=${encodeURIComponent(item.storageUnitName)}&facilityName=${encodeURIComponent(item.facilityName)}`}
                            ><b>{item.storageUnitName}</b></Link>
                          </div>
                        </td>
                        <td className="text-center">
                          {item.expired ? (
                            <CheckCircleOutlineIcon className='align_icon' color="green" />
                          ) : (
                            <Link
                              to={`/expiredItems?storageUnitId=${encodeURIComponent(item.storageUnitId)}&facilityName=${encodeURIComponent(item.facilityName)}&cart=${encodeURIComponent(item.cart)}&&storageUnitName=${encodeURIComponent(item.storageUnitName)}&facilityName=${encodeURIComponent(item.facilityName)}`}
                              style={{ textDecoration: 'none' }}
                            >
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="dashboard-icons align_dashboard_icon"
                              />
                            </Link>
                          )}
                        </td>

                        <td className="text-center">
                          {item.missingItems ? (
                            <CheckCircleOutlineIcon className='align_icon' color="green" />
                          ) : (
                            <Link
                              to={`/missingItems?storageUnitId=${encodeURIComponent(item.storageUnitId)}&facilityName=${encodeURIComponent(item.facilityName)}&cart=${encodeURIComponent(item.cart)}&storageUnitName=${encodeURIComponent(item.storageUnitName)}&facilityName=${encodeURIComponent(item.facilityName)}`}
                              style={{ textDecoration: 'none' }}
                            >
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="dashboard-icons align_dashboard_icon"
                              />
                            </Link>
                          )}
                        </td>

                        <td className="table-column-width2 text-center">
                          {item.recall ? (
                            <CheckCircleOutlineIcon className='align_icon' color="green" />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-icons align_dashboard_icon"
                              icon={faExclamationTriangle}
                              onClick={() => handleClick(item.storageUnitName, item.storageUnitId, 'recallItems')}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          {item.alert ? (
                            <CheckCircleOutlineIcon className='align_icon' color="green" />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-icons align_dashboard_icon"
                              icon={faExclamationTriangle}
                              onClick={() => handleClick(item.storageUnitName, item.storageUnitId, 'storageUnitChecks')}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          {item.batteryCheck ? (
                            <CheckCircleOutlineIcon className='align_icon' color="green" />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-icons align_dashboard_icon"
                              icon={faExclamationTriangle}
                              onClick={() => handleClick(item.storageUnitName, item.storageUnitId, 'batteryChecks')}
                            />
                          )}
                        </td>
                        <td className="text-center dashboard_button_align " >
                          {item.trackDate && <span className="date-text">{item.trackDate}</span>}
                          {/* <button className="btn custom-outline" onClick={() => navigate('/activities')}>Track</button> */}
                          <td className="text-center dashboard_button_align " >
                            {item.trackDate && <span className="date-text">{item.trackDate}</span>}
                            <button className="btn custom-outline"
                              onClick={() => handleClick(item.storageUnitName, item.storageUnitId, 'activities')}
                            >Track</button>
                          </td>
                        </td>
                        <td className="text-center dashboard_button_align " >
                          <button className="btn custom-outline" style={{ marginTop: '1.6rem' }} onClick={() => handleOpenAddQuantitiesModal(item)}>Add</button>
                          {item.addDate && <span className="date-text">{item.addDate}</span>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* </div> */}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row >
        : <Card >
          <CardBody >
            <span style={{ marginBottom: 20, fontSize: 14, fontWeight: 500, color: '#00324d' }}>COMPANIES</span>
            <span style={{ fontSize: 14, fontWeight: 500, color: '#00324d', float: 'right' }}>
              <span className='status_color'><Circle color='#006699' size={15} /> Active</span>
              <span className='status_color'><Circle color='#6c757d' size={15} /> Pending</span>
              <span className='status_color'><Circle color='#ff0000' size={15} /> Blocked</span>
              <span className='status_color'><Circle color='#e6e600' size={15} /> Suspended</span>
            </span>

            <Row style={{ marginTop: 20 }}>
              {companiesData && companiesData.map((company, index) => {
                return (
                  <Col md={12} xl={3} lg={6} xs={12} key={index}>
                    <Card>
                      <CardBody
                        style={company.status === 'Active' ? { background: '#006699' } : company.status === 'Blocked' ?
                          { background: '#ff0000' } : company.status === 'suspend' ?
                            { background: '#e6e600' } : { background: 'grey' }}
                        key={company._id}
                        className="dashboard__card-widget"
                      >
                        <Row className="dashboard__total1">
                          <p className="dashboard__total-stat">
                            <span style={{ color: 'white' }}>{company.companyName}</span>
                          </p>
                        </Row>
                        <Row>
                          <Col xs={12} style={{ color: 'white', marginTop: 30 }}>
                            <Row style={{ cursor: 'pointer' }} onClick={() => redirectToFacility(company.companyName, company.facilities[0] ? company.facilities[0]._id : null, company.companyId)}>
                              Facilities: {company.facilities && company.facilities.length > 0 ? company.facilities[0].count : 0}
                            </Row>
                            <Row style={{ cursor: 'pointer' }} onClick={() => redirectToStorageUnit(company.companyName, company.storageUnits[0] ? company.storageUnits[0]._id : null, company.companyId)}>
                              Storage Units: {company.storageUnits && company.storageUnits.length > 0 ? company.storageUnits[0].count : 0}
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}
            </Row>

          </CardBody >
        </Card >

      }

      {/* Add Quantities Modal */}
      {
        showAddQuantitiesModal && (
          <AddQuantitesModal
            openQuantitesModal={showAddQuantitiesModal}
            closeFormModal={handleCloseAddQuantitiesModal}
            type={'dashBoard'}
            formType={'formType'}
            apiUrl={'productQuantities'}
            tableRowData={selectedRowData}
            displayViewOfForm="modal"
            productQuantitesUrl={apiCalls["productQuantities"]}
            tabType={'tabType'}
            filterCriteria={'filterCriteria'}
            getDataFromServer={getInventoryList}
            storageUnitName={selectedRowData.storageUnitName}
            facilityName={selectedRowData.facilityName}
            facilityId={selectedRowData.facilityId}
            storageUnitId={selectedRowData.storageUnitId}
            allUsersData={'allUsersData'}
          />
        )
      }
    </Container >
  );
};

export default Dashboard;
